import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./AnaMonso.module.css";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';

import AonePhoto5 from "../../../../res/Photos site/A-one/A-ONE-portrait.jpg";

import AnaPdp from "./../../../../res/monso/portrait.jpeg";
import Button from "../../../../components/Button";
import PastPresent1 from "../../../../res/monso/oeuvre-2.png"
import PastPresent2 from "../../../../res/monso/oeuvre-1.png"
import PastPresent3 from "../../../../res/monso/oeuvre-3.png"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "ana-monso",
  name: "ANA MONSÓ",
  description: "Having studied art and design in Cambridge and then fashion design at the University of the Arts in London from which she graduated in 2020. In 2021, she presented her first solo exhibition at the Pigment Gallery in Barcelona, which introduced her to the Art Nou fair. Since 2021, her works have been exhibited in several contemporary art fairs in Europe, and in June 2022, she presents her second solo exhibition entitled \"Ode to Childhood\" at the Pigment Gallery in Barcelona. She is currently one of the resident artists in the contemporary art program Piramidón in Barcelona along with twenty other artists and has just been selected for a Master's degree at the Royal Academy of Art in London in the fall of 2023.",
  citations: [
    "QUAND JE SERAI \"GRANDE\", JE VEUX REDEVENIR UN ENFANT",
    "Je centre mon travail sur l'auto-réflexion des moments sacrés de ma vie. Puisant dans mes souvenirs d'enfance, je crée librement, permettant à l'oeuvre finale d'être une fusion de mes inspirations. Mon travail est une ode à l'innocence enfantine latente. J'essaie de réinterpréter le monde sans règles et sans limites, comme le ferait un enfant avant que le jugement social et les normes établies ne lui soient imposées. Ana Monsó"
    // "« Good Bye My Hit master your work is done only on this mold of a planet ! You always Drew a Great Ikonoklast Panzerism letter Tank for me… Fly in your \"A\" far and Hit to hurt as you always have... Like my #1...We all know you will \"ROCK THE GALAXY\" now that you've got the chance... So Burn'em! I will be watching »"
  ],
  pdp: AnaPdp,
  alt_pdp: "Photo de profil de ANA MONSÓ.",
  photos: [
    { src: PastPresent1, name: "ANA MONSÓ" },
    { src: PastPresent2, name: "ANA MONSÓ" },
    { src: PastPresent3, name: "ANA MONSÓ" },
  ],
};

const AnaMonso = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
   {/* <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet> */}
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>ANA MONSÓ</h1>
          <h3 className={NameWrapper}>Born in 1998</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <br />
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
      </div>
      </div>

      <div className={PhotosWrapper}>
        <Title title={"Art"} />
        <div className={CarrouselWrapper2}>
        <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                  style={{objectFit: "contain"}}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default AnaMonso;