import * as React from "react";
import Layout from "../../../components/fr/Layout";
import AnaMonso from "../../../nav/fr-projects/Artistes/Ana Monso";
import { Helmet } from "react-helmet";
import LayoutProjects from "../../../components/fr/LayoutProjects";

const AnaMonsoPage = () => {
  return (
    <LayoutProjects>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ANA MONSÓ | GHOST GALERIE</title>
        <meta
          name="description"
          content="GHOST galerie, Marseille. New York City : Post Graffiti • Old School | 80's & 90's"
        />
        <meta name="keywords" content="mathiasbensimon,anamonso,ghostgalerie,parisexhibition,expositionparis,artcontemporainparis,contemporaryartgalleryparis" />
      </Helmet>
      <AnaMonso />
    </LayoutProjects>
  );
};

export default AnaMonsoPage;
