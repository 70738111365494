// extracted by mini-css-extract-plugin
export var ArtistDescription = "AnaMonso-module--ArtistDescription--nXRiC";
export var ArtistInfos = "AnaMonso-module--ArtistInfos--twA3J";
export var ButtonWrapper = "AnaMonso-module--ButtonWrapper --Pd0sB";
export var CardWrapper = "AnaMonso-module--CardWrapper--LzPcU";
export var CarrouselWrapper2 = "AnaMonso-module--CarrouselWrapper2--7kGpY";
export var Citations = "AnaMonso-module--Citations--4Z-rz";
export var DescriptionWrapper = "AnaMonso-module--DescriptionWrapper--pj-MU";
export var ImageWrapper = "AnaMonso-module--ImageWrapper--NB+Mx";
export var LinkWrapper = "AnaMonso-module--LinkWrapper---jopD";
export var MobileProtrait = "AnaMonso-module--MobileProtrait--PQdgr";
export var More = "AnaMonso-module--More--ntPVK";
export var MoreButton = "AnaMonso-module--MoreButton--wxpMG";
export var NameWrapper = "AnaMonso-module--NameWrapper--TL4iB";
export var PdpWrapper = "AnaMonso-module--PdpWrapper--v7f6b";
export var PhotosWrapper = "AnaMonso-module--PhotosWrapper--vVXN3";
export var ProfilWrapper = "AnaMonso-module--ProfilWrapper--YLApb";
export var TitleWrapper = "AnaMonso-module--TitleWrapper--FKhbb";
export var Wrapper = "AnaMonso-module--Wrapper--GQtVV";